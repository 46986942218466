import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// @types
import { SiteState, SiteColumnsEnum, SiteColumn, UpdateVulnerabilityRow } from 'src/@types/site';
import { VisualDiffs } from '@joonasvanhatapio/wp-cloud-backend-types';
// constants
import { SITE_COLUMN } from 'src/config';

// ----------------------------------------------------------------------

const initialState: SiteState = {
  // Sites table customization
  selectedCols: Array.from(SITE_COLUMN).filter(
    (col) =>
      col.id === SiteColumnsEnum.diskusage ||
      col.id === SiteColumnsEnum.performance ||
      col.id === SiteColumnsEnum.lcp ||
      col.id === SiteColumnsEnum.plan
  ),
  unselectedCol: Array.from(SITE_COLUMN).filter(
    (col) =>
      col.id !== SiteColumnsEnum.diskusage &&
      col.id !== SiteColumnsEnum.performance &&
      col.id !== SiteColumnsEnum.lcp &&
      col.id !== SiteColumnsEnum.plan
  ),
  // Created sites => Display created site banner
  createdSites: [],
  // Sites with disk usage upgraded => Display successfully/fail banner
  diskUpgradedSites: [],
  // Sites with plan changed => Display upgraded/downgraded successfully/fail banner
  planChangedSites: [],
  // Vulnerabilities that are currently being updated
  updatingVulnsInfo: [],
  // Update issue display
  currentIssue: [],
};

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    // SET INITIAL SELECTED COL
    setInitialCols(state, action: PayloadAction<SiteColumnsEnum[]>) {
      if (action.payload.length < 0 || action.payload.length > 6) return;

      state.selectedCols = action.payload.reduce<SiteColumn[]>((selectedCols, tableHead) => {
        const col = SITE_COLUMN.find((col) => col.id === tableHead);
        if (col) {
          selectedCols.push(col);
        }
        return selectedCols;
      }, []);

      state.unselectedCol = SITE_COLUMN.filter((col) => !action.payload.includes(col.id));
    },
    // ADD SELECTED COL TO DISPLAY
    addSelectedCol(state, action: PayloadAction<{ destinationIndex: number; dragColId: string }>) {
      const sourceIndexFromUnselectedArr = state.unselectedCol.findIndex(
        (col) => col.id === action.payload.dragColId
      );
      const draggingCol = state.unselectedCol[sourceIndexFromUnselectedArr];

      state.selectedCols.splice(action.payload.destinationIndex, 0, draggingCol);
      state.unselectedCol.splice(sourceIndexFromUnselectedArr, 1);
    },
    // REMOVE SELECTED COL TO DISPLAY
    removeSelectedCol(state, action: PayloadAction<{ dragColId: string }>) {
      const sourceIndexFromSelectedArr = state.selectedCols.findIndex(
        (col) => col.id === action.payload.dragColId
      );
      const removingCol = state.selectedCols[sourceIndexFromSelectedArr];

      state.selectedCols.splice(sourceIndexFromSelectedArr, 1);
      state.unselectedCol.push(removingCol);
    },
    // REORDER SELECTED COLS
    reorderSelectedCols(
      state,
      action: PayloadAction<{ destinationIndex: number; dragColId: string }>
    ) {
      const sourceIndexFromSelectedArr = state.selectedCols.findIndex(
        (col) => col.id === action.payload.dragColId
      );
      const draggingCol = state.selectedCols[sourceIndexFromSelectedArr];

      state.selectedCols.splice(sourceIndexFromSelectedArr, 1);
      state.selectedCols.splice(action.payload.destinationIndex, 0, draggingCol);
    },
    // SET NEWLY CREATED SITE
    setCreatedSites(state, action: PayloadAction<string[]>) {
      state.createdSites = action.payload;
    },
    // ADD DISK UPGRADED SITE
    addDiskUpgradedSite(
      state,
      action: PayloadAction<{ siteName: string; isUpgradedSuccessfully: boolean }>
    ) {
      state.diskUpgradedSites = [...state.diskUpgradedSites, action.payload];
    },
    // REMOVE DISK UPGRADED SITE
    removeDiskUpgradedSite(state, action: PayloadAction<string>) {
      state.diskUpgradedSites = state.diskUpgradedSites.filter(
        (site) => site.siteName !== action.payload
      );
    },
    // ADD PLAN CHANGED SITE
    addChangedPlanSite(
      state,
      action: PayloadAction<{ siteName: string; isUpgraded: boolean; isSuccess: boolean }>
    ) {
      state.planChangedSites = [...state.planChangedSites, action.payload];
    },
    // REMOVE PLAN UPGRADED SITES
    removeChangedPlanSite(state, action: PayloadAction<string>) {
      state.planChangedSites = state.planChangedSites.filter(
        (site) => site.siteName !== action.payload
      );
    },
    // ADD SITE UPDATING VULNS
    addUpdatingVulns(
      state,
      action: PayloadAction<{ siteName: string; newUpdatingVulns: UpdateVulnerabilityRow[] }>
    ) {
      const { siteName, newUpdatingVulns } = action.payload;

      // If this site doesn't have any updating at the moment
      if (
        state.updatingVulnsInfo.filter((siteInfo) => siteInfo.siteName === siteName).length === 0
      ) {
        state.updatingVulnsInfo = [
          ...state.updatingVulnsInfo,
          { siteName: siteName, updatingVulns: newUpdatingVulns },
        ];
      }
      // If this site already has updates at the moment
      else {
        state.updatingVulnsInfo = state.updatingVulnsInfo.map((siteInfo) => {
          if (siteInfo.siteName === siteName) {
            return {
              ...siteInfo,
              updatingVulns: [...siteInfo.updatingVulns, ...newUpdatingVulns],
            };
          }

          return siteInfo;
        });
      }
    },
    // REMOVE SITE UPDATING VULNS
    removeUpdatingVulns(
      state,
      action: PayloadAction<{ siteName: string; removeUpdatingVulns: UpdateVulnerabilityRow[] }>
    ) {
      state.updatingVulnsInfo = state.updatingVulnsInfo
        .map((siteInfo) => {
          if (siteInfo.siteName === action.payload.siteName) {
            return {
              ...siteInfo,
              updatingVulns: siteInfo.updatingVulns.filter(
                (updatingVuln) =>
                  !action.payload.removeUpdatingVulns.some((vuln) => vuln.id === updatingVuln.id)
              ),
            };
          }

          return siteInfo;
        })
        .filter((siteInfo) => siteInfo.updatingVulns.length > 0);
    },
    // SET CURRENT ISSUE VISUAL DIFFS
    setCurrentIssue(state, action: PayloadAction<VisualDiffs[]>) {
      state.currentIssue = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  // Sites table columns
  setInitialCols,
  addSelectedCol,
  removeSelectedCol,
  reorderSelectedCols,
  // Created
  setCreatedSites,
  // Disk upgraded
  addDiskUpgradedSite,
  removeDiskUpgradedSite,
  // Plan changed
  addChangedPlanSite,
  removeChangedPlanSite,
  // Updating vulnerabilities
  addUpdatingVulns,
  removeUpdatingVulns,
  // Current update issue
  setCurrentIssue,
} = slice.actions;
