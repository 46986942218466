import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { user } = useSelector((state) => state.auth);

  if (user) {
    return <Navigate to="/sites" replace />;
  }

  return <>{children}</>;
}
