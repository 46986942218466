import { matchPath } from 'react-router-dom';
// @types
import { UserDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { BrandIdEnum, UserRoleEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

export function isExceedThreeMonths(date: Date) {
  const today = new Date();
  const differenceInTime = today.getTime() - date.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays > 90;
}

export function isActivePage(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: false }, pathname) : false;
}

export function isHostnetCustomer(user: UserDTO | null) {
  if (user && user.brand?.id === BrandIdEnum.hostnet && user.role === UserRoleEnum.customer) {
    return true;
  }

  return false;
}
