import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function SiteBackupDetailsGuard({ children }: Props) {
  const { name } = useParams();

  // STATE
  const { backupsInProgress } = useSelector((state) => state.backup);

  if (!name || !backupsInProgress.find((backup) => backup.settings.hostname === name)) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
