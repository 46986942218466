import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useLocation, createBrowserRouter } from 'react-router-dom';
// @types
import { UserRoleEnum } from 'src/@types/user';
// layouts
import LogoLayout from 'src/layouts/LogoLayout';
import MainLayout from 'src/layouts/main';
// guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import PageGuard from 'src/guards/PageGuard';
import PageDetailsTabGuard from 'src/guards/PageDetailsTabGuard';
import SiteDetailsGuard from 'src/guards/SiteDetailsGuard';
import SiteBackupDetailsGuard from 'src/guards/SiteBackupDetailsGuard';
import CreateSitePlanGuard from 'src/guards/CreateSitePlanGuard';
import ChangeSitePlanGuard from 'src/guards/ChangeSitePlanGuard';
import DnsDetailsGuard from 'src/guards/DnsDetailsGuard';
// config
import {
  ACCOUNT_SETTINGS_NAV_CONFIG,
  DNS_DETAILS_NAV_CONFIG,
  SUPPORT_DATA_NAV_CONFIG,
} from 'src/config';
// components
import LoadingScreen from 'src/components/LoadingScreen';
import ScrollToTop from 'src/components/ScrollToTop';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/sites')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// Before authenticated
const InitialAuth = Loadable(lazy(() => import('src/pages/auth/InitialAuth')));
const Login = Loadable(lazy(() => import('src/pages/auth/Login')));
const CheckEmail = Loadable(lazy(() => import('src/pages/auth/CheckEmail')));
// After authenticated
// Sites
const Sites = Loadable(lazy(() => import('src/pages/sites/Sites')));
const PlanSelection = Loadable(lazy(() => import('src/pages/sites/SitePlanSelection')));
const TableCustomize = Loadable(lazy(() => import('src/pages/sites/TableCustomize')));
const SiteMigration = Loadable(lazy(() => import('src/pages/sites/SiteMigration')));
const SiteDetails = Loadable(lazy(() => import('src/pages/sites/details')));
const SiteBackupDetails = Loadable(lazy(() => import('src/pages/sites/details/SiteBackupDetails')));
// Dns
const Dns = Loadable(lazy(() => import('src/pages/dns/Dns')));
const DnsDetails = Loadable(lazy(() => import('src/pages/dns/details')));
// Customers
const Customers = Loadable(lazy(() => import('src/pages/customers/Customers')));
const AddSitePermission = Loadable(lazy(() => import('src/pages/customers/AddSitePermission')));
const ChangePermission = Loadable(lazy(() => import('src/pages/customers/ChangePermission')));
// Plans
const ChangeHistory = Loadable(lazy(() => import('src/pages/plan/ChangeHistory')));
// Data
const Data = Loadable(lazy(() => import('src/pages/data')));
// Settings
const SettingDetails = Loadable(lazy(() => import('src/pages/settings')));
// Other
const Forbidden = Loadable(lazy(() => import('src/pages/Page403')));
const NotFound = Loadable(lazy(() => import('src/pages/Page404')));

// ----------------------------------------------------------------------

const router = createBrowserRouter([
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <InitialAuth />
      </GuestGuard>
    ),
  },
  {
    path: '/',
    children: [
      { element: <Navigate to="/login" replace />, index: true },
      {
        path: 'login',
        children: [
          {
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
            index: true,
          },
          {
            path: 'check-email',
            element: (
              <GuestGuard>
                <CheckEmail />
              </GuestGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
        <ScrollToTop />
      </AuthGuard>
    ),
    children: [
      {
        path: 'sites',
        children: [
          { element: <Sites />, index: true },
          {
            path: 'create-site',
            element: (
              <CreateSitePlanGuard>
                <PlanSelection />
              </CreateSitePlanGuard>
            ),
          },
          {
            path: ':cluster/:namespace/:name/change-plan',
            element: (
              <ChangeSitePlanGuard>
                <PlanSelection />
              </ChangeSitePlanGuard>
            ),
          },
          {
            path: 'migration',
            element: <SiteMigration />,
          },
          {
            path: 'customize',
            element: <TableCustomize />,
          },
          {
            path: ':tabname/:cluster/:namespace/:name',
            element: (
              <SiteDetailsGuard>
                <SiteDetails />
              </SiteDetailsGuard>
            ),
          },
          {
            path: 'backups/:cluster/:namespace/:name/details',
            element: (
              <SiteBackupDetailsGuard>
                <SiteBackupDetails />
              </SiteBackupDetailsGuard>
            ),
          },
        ],
      },
      {
        path: 'dns',
        children: [
          { element: <Dns />, index: true },
          {
            path: ':tabname/:name',
            element: (
              <PageDetailsTabGuard navConfig={DNS_DETAILS_NAV_CONFIG}>
                <DnsDetailsGuard>
                  <DnsDetails />
                </DnsDetailsGuard>
              </PageDetailsTabGuard>
            ),
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            element: (
              <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
                <Customers />
              </PageGuard>
            ),
            index: true,
          },
          {
            path: 'permission',
            element: (
              <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
                <AddSitePermission />
              </PageGuard>
            ),
          },
          {
            path: ':email',
            element: (
              <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
                <ChangePermission />
              </PageGuard>
            ),
          },
        ],
      },
      {
        path: 'plan-change-history',
        element: (
          <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
            <ChangeHistory />
          </PageGuard>
        ),
      },
      {
        path: 'data/:tabname',
        element: (
          <PageDetailsTabGuard navConfig={SUPPORT_DATA_NAV_CONFIG}>
            <Data />
          </PageDetailsTabGuard>
        ),
      },
      {
        path: 'settings/:tabname',
        element: (
          <PageDetailsTabGuard navConfig={ACCOUNT_SETTINGS_NAV_CONFIG}>
            <SettingDetails />
          </PageDetailsTabGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <LogoLayout />,
    children: [
      { path: '403', element: <Forbidden /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
]);

export default router;