import { useState, useEffect, RefObject } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import ReactGA from 'react-ga4';
// redux
import { persistor, useDispatch, useSelector } from 'src/redux/store';
import { useLogoutMutation } from 'src/redux/api/authApi';
import { useUpdateUserLocaleMutation } from 'src/redux/api/userApi';
import { setUser } from 'src/redux/features/auth';
// @types
import { LocaleEnum, UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { isActivePage } from 'src/utils/check';
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// config
import { NAV_CONFIG } from 'src/config';

// ----------------------------------------------------------------------

type Props = {
  dashboardDrawerRef: RefObject<HTMLDivElement>;
  openDashboardDrawer: boolean;
  handleCloseDashboardDrawer: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DashboardDrawer({
  dashboardDrawerRef,
  openDashboardDrawer,
  handleCloseDashboardDrawer,
}: Props) {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { allLangs, currentLang, onChangeLang, translate } = useLocales();

  const isDesktop = useResponsive('up', 'desktop_min');

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [showLocales, setShowLocales] = useState(false);

  // API
  const [updateUserLocale] = useUpdateUserLocaleMutation();

  const [logout] = useLogoutMutation();

  // EVENT FUNCTION
  function handleChangeLanguage(newLang: string) {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `change-language-to-${newLang}`,
    });

    updateUserLocale({ locale: newLang as LocaleEnum })
      .unwrap()
      .then(() => {
        // Change chat widget locale
        if (user?.brand?.id !== 4) {
          ZendeskAPI('webWidget', 'setLocale', newLang);
        }
        // Change browser locale
        onChangeLang(newLang);
        // Update stored redux user
        dispatch(
          setUser({
            ...user,
            locale: newLang as LocaleEnum,
          })
        );
      })
      .catch(() => {
        displayToast(translate('wpone.navbar.locale.toast.error'), { variant: 'alert' });
      });
  }

  function handleLogout() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'logout',
    });

    const logoutCleanup = () => {
      localStorage.removeItem('refresh_token');
      dispatch(setUser(null));
      persistor.purge();
      navigate('/login');
    };
    // This is for development only
    if (window.location.hostname === 'localhost' && window.location.port === '3000') {
      localStorage.removeItem('access_token');
      logoutCleanup();
    } else {
      logout()
        .unwrap()
        .then(() => {
          logoutCleanup();
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    }
  }

  //
  useEffect(() => () => handleCloseDashboardDrawer(), [handleCloseDashboardDrawer]);

  return (
    <div className="gv-activated">
      <div className="gv-sidedrawer gv-sidedrawer-open" style={{ zIndex: 1200 }}>
        {/* Here not only we need to make the body overflow to hidden but also we need to add the style overflow hidden to the drawer content as well, this is for preventing drawer content to be scrollable when broswer height is small */}
        <div
          className="gv-side-content gv-pos-right"
          ref={dashboardDrawerRef}
          style={{
            overflow: openDashboardDrawer ? 'hidden' : 'auto',
          }}
        >
          <button className="gv-btn-close" onClick={handleCloseDashboardDrawer}>
            <gv-icon src="/src/icons/close.svg" />
          </button>
          <div className="gv-side-account">
            <div className="gv-account-info">
              <span className="gv-name">{user ? user.name : 'User'}</span>
              <span className="gv-sub">{user ? user.email : 'user@gmail.com'}</span>
            </div>
          </div>

          {!isDesktop && (
            <div className="gv-side-menu">
              <div className="gv-side-label">WP.one</div>
              <nav className="gv-side-nav">
                <ul>
                  {(user?.role !== UserRoleEnum.customer
                    ? user?.role !== UserRoleEnum.support
                      ? NAV_CONFIG.slice(0, 4)
                      : NAV_CONFIG
                    : NAV_CONFIG.slice(0, 2)
                  ).map((navLink, index) => (
                    <RouterLink
                      key={index}
                      to={navLink.path}
                      className={`gv-nav-item${
                        isActivePage(navLink.path, pathname) ? ' gv-active' : ''
                      }`}
                      onClick={handleCloseDashboardDrawer}
                    >
                      <gv-icon src={`/src/icons/${navLink.icon}.svg`} />
                      <span className="gv-text-truncate">{translate(navLink.title)}</span>
                    </RouterLink>
                  ))}
                </ul>
              </nav>
            </div>
          )}

          <div className="gv-side-preferences">
            <div className="gv-side-label">{translate('wpone.navbar.preferences')}</div>
            <nav className="gv-side-nav">
              <ul>
                <li>
                  <RouterLink
                    to="/settings/profile"
                    className={`gv-nav-item${
                      isActivePage('/settings', pathname) ? ' gv-active' : ''
                    }`}
                    onClick={handleCloseDashboardDrawer}
                  >
                    <gv-icon src="/src/icons/settings_account_box.svg" />
                    <span className="gv-text-truncate">{translate('wpone.navbar.settings')}</span>
                  </RouterLink>
                </li>
                <li>
                  <button
                    className="gv-nav-item"
                    aria-expanded={showLocales}
                    onClick={() => setShowLocales((prev) => !prev)}
                  >
                    <gv-icon src="/src/icons/language.svg" />
                    <span className="gv-text-truncate">
                      {translate(`wpone.general.locale.${currentLang.value}`)}
                    </span>
                    <gv-icon src="/src/icons/keyboard_arrow_down.svg" />
                  </button>
                  <div className="gv-side-dropdown">
                    <ul role="listbox">
                      {allLangs.map((option) => (
                        <li key={option.value}>
                          <a
                            lang={translate(`wpone.general.locale.${option.value}`)}
                            hrefLang={translate(`wpone.general.locale.${option.value}`)}
                            href="/#"
                            aria-selected={option.value === currentLang.value}
                            role="option"
                            className="gv-nav-item gv-text-truncate"
                            onClick={(event) => {
                              event.preventDefault(); // Prevents default navigation behavior
                              if (option.value === currentLang.value) {
                                setShowLocales((prev) => !prev);
                              } else {
                                handleChangeLanguage(option.value);
                                handleCloseDashboardDrawer();
                              }
                            }}
                          >
                            {translate(`wpone.general.locale.${option.value}`)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li>
                  <button className="gv-nav-item" onClick={handleLogout}>
                    <gv-icon src="/src/icons/logout.svg" />
                    <span className="gv-text-truncate">{translate('wpone.navbar.logout')}</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
