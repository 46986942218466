import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer, RootState } from './rootReducer';
// api
import { authApi } from './api/authApi';
import { siteApi } from './api/siteApi';
import { resourceApi } from './api/resourceApi';
import { updateApi } from './api/updateApi';
import { domainApi } from './api/domainApi';
import { userApi } from './api/userApi';
import { wpInstallOptionsApi } from './api/wpInstallOptionsApi';
import { migrationApi } from './api/migrationApi';
import { backupApi } from './api/backupApi';
import { wordpressApi } from './api/wordpressApi';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      authApi.middleware,
      siteApi.middleware,
      resourceApi.middleware,
      updateApi.middleware,
      domainApi.middleware,
      userApi.middleware,
      wpInstallOptionsApi.middleware,
      migrationApi.middleware,
      backupApi.middleware,
      wordpressApi.middleware,
    ]),
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
